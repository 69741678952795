<template>
  <div class="create-new-flayer">
    <div v-loading="is_pending" class="create-new-flayer-form">
        <div class="create-new-flayer-form-header">
            <h2 v-if="!edit">יצירת פלאייר חדש</h2>
            <h2 v-if="edit">עריכת פלאייר קיים</h2>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="create-new-flayer-form-content">
            <div class="create-new-flayer-form-content-field">
                <h2>שם הפלאייר</h2>
                <el-input v-model="values.flayer_name" placeholder="שם הפלאייר" />
            </div>
            <div class="create-new-flayer-form-content-field">
                <h2>מתאריך</h2>
                <el-input type="date" v-model="values.start_date" placeholder="מתאריך" />
            </div>
            <div class="create-new-flayer-form-content-field">
                <h2>עד תאריך</h2>
                <el-input type="date" v-model="values.end_date" placeholder="עד תאריך" />
            </div>
            <div class="create-new-flayer-form-content-field">
                <h2>לקוחות מרכזים</h2>
                <el-select
                    style="width:100%;" 
                    v-model="values.asortment"
                    multiple
                    collapse-tags
                    filterable
                    placeholder="בחירת לקוחות מרכז"
                    >
                    <el-option
                        v-for="asortment in asortments_list"
                        :key="asortment.makat"
                        :label="asortment.name"
                        :value="asortment.makat"
                    />
                </el-select>
            </div>
            <div class="create-new-flayer-form-content-field">
                <h2>מחלקה</h2>
                <el-select style="width:100%;" v-model="selected_dep" class="m-2" placeholder="מחלקה" size="large">
                    <el-option
                    v-for="d in departments_list"
                    :key="d"
                    :value="d"
                    />
                </el-select>
            </div>
            <div class="create-new-flayer-form-content-field">
                <h2>תת קטגוריה</h2>
                <el-select style="width:100%;" v-model="values.sub_categoty" class="m-2" placeholder="מחלקה" size="large">
                    <el-option
                    v-for="sub in get_sub_category"
                    :key="sub"
                    :value="sub"
                    />
                </el-select>
            </div>
            <div v-if="!upload_pending" class="create-new-flayer-form-content-field">
                <h2>העלאת קובץ תמונה</h2>
                <input type="file" @change="handleChange">
            </div>
            <div v-if="upload_pending" class="create-new-flayer-form-content-field">
                <h2 style="color:red;">אנא המתן להעלאת הקובץ...</h2>
            </div>
            <template v-for="(image,index) in values.flayer_images" :key="image">
                <div v-if="!upload_pending" class="display-pdf">
                    <a :href="image" target="_blank">הצג קובץ</a>
                    <small @click="handle_remove_url(index)" class="remove">הסר</small>
                </div>
            </template>
        </div>
        <div class="create-new-flayer-form-footer">
            <el-button v-if="!edit" @click="handle_submit" style="width:80%;" type="success" :disabled="upload_pending">צור חדש</el-button>
            <el-button v-if="edit" @click="handle_submit_edit" style="width:80%;" type="warning" :disabled="upload_pending">עדכון</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_asortments,store_new_pdf_in_DB,store_edit_pdf_in_DB,get_all_departments} from './Scripts/script'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import upload_image_storage from '../../../../Methods/use_general_storage'
import { computed } from '@vue/runtime-core'


export default {
    emits:['close','finish','finish_edit'],
    props:['edit'],
    setup(props,{emit}){

        const selected_dep = ref('')
        const departments_list = ref([])
        const sub_departments = ref(null)
        
        const is_pending = ref(false)
        const err_msg = ref('')
        const upload_pending = ref(false)

        const {url,uploadImage,delete_image_by_url}  = upload_image_storage()

        const asortments_list = ref([])

        const values = ref({
            flayer_name:'',
            start_date:'',
            end_date:'',
            asortment:[],
            sub_categoty:'',
            flayer_images:[],
        })

        const get_sub_category = ref(computed(()=>{
            if(sub_departments.value){
                return sub_departments.value[selected_dep.value]
            }
            return []
        }))

        const handle_remove_url = async(index)=>{
            await delete_image_by_url(values.value.flayer_images[index])
            values.value.flayer_images.splice(index,1)
        }

        const validation = () => {
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'flayer_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם לפלאייר!'
                            return false
                        }
                        break;
                    case 'start_date':
                        if(!value){
                            err_msg.value = 'עליך להזין תאריך התחלה!'
                            return false
                        }
                        break;
                    case 'end_date':
                        if(!value){
                            err_msg.value = 'עליך להזין תאריך סיום!'
                            return false
                        }
                        break;
                    case 'asortment':
                        if(value.length == 0){
                            err_msg.value = 'עליך לבחור לפחות לקוח מרכז אחד!'
                            return false
                        }
                        break;
                    case 'sub_categoty':
                        if(!value){
                            err_msg.value = 'עליך לבחור תת קטגוריה!'
                            return false
                        }
                        break;
                    case 'flayer_images':
                        if(value.length == 0){
                            err_msg.value = 'עליך להעלות קובץ תמונה לפחות אחד!'
                            return false
                        }
                        break;    
                }
            }
            return true
        }

        const handle_submit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                await store_new_pdf_in_DB(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                `הפלאייר ${values.value.flayer_name} נשמר בהצלחה`)
                .then(()=>{
                    emit('finish')
                })
            }

        }

        const handle_submit_edit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                await store_edit_pdf_in_DB(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                `הפלאייר ${values.value.flayer_name} עודכן בהצלחה`)
                .then(()=>{
                    emit('finish_edit',values.value)
                })
            }
        }

        const handleChange = async(e) => {
            const type = ['image/png','image/jpg','image/jpeg']
            const selected=e.target.files[0]
            if(selected && type.includes(selected.type)){
                upload_pending.value = true
                await uploadImage(selected,`Flayers/${new Date().toLocaleString('he')}`)
                values.value.flayer_images.push(url.value)
                upload_pending.value = false
            }else{
                slide_pop_error('עליך להעלות קובץ בפורמט PDF או JPG בלבד!')
            }
        }

        const init = async()=>{
            if(props.edit){
                values.value = props.edit
            }
            asortments_list.value = await get_asortments()
            const {dep_names,departments} =  await get_all_departments()
            departments_list.value = dep_names
            sub_departments.value = departments
        }

        init()
        return{
            handle_remove_url,
            handle_submit,
            handleChange,
            handle_submit_edit,
            init,
            values,
            asortments_list,
            upload_pending,
            err_msg,
            is_pending,
            departments_list,
            selected_dep,
            get_sub_category,
        }
    }
}
</script>

<style scoped>
    .create-new-flayer{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
    }
    .create-new-flayer-form{
        width: 100%;
        max-width: 500px;
      
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    .create-new-flayer-form-header{
        position: relative;
        width: 100%;
        height: 50px;
        background: var(--success);
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    .create-new-flayer-form-content{
        width: 100%;
        flex: 1;
        max-height: 500px;
        padding: 5px 0;
        overflow-y:auto ;
    }
    .create-new-flayer-form-content-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 10px;
        padding: 0 5px;
    }
    .create-new-flayer-form-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .display-pdf{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 5px;
        font-size: 25px;
    }
    .display-pdf .remove{
        color: var(--danger);
        margin-right: 10px;
        cursor: pointer;
    }
</style>